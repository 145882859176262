import { setCookie } from "./cookieUtils";

/**
 * sets the `--primary-color` css class to `primaryColor`.
 * sets the `--secondary-color` css class to `secondaryColor`.
 */
export function setColorSheme(primaryColor, secondaryColor) {
  // set cookies
  setCookie("primaryColor", primaryColor, 7);
  setCookie("secondaryColor", secondaryColor, 7);

  // set css properties
  primaryColor &&
    document.documentElement.style.setProperty("--primary-color", primaryColor);
  secondaryColor &&
    document.documentElement.style.setProperty(
      "--secondary-color",
      secondaryColor
    );
}

// decides if white or black has a better contrast to `color`
export function whiteOrBlack(color) {
  color = color.charAt(0) === "#" ? color.substring(1, 7) : color;
  var r = parseInt(color.substring(0, 2), 16); // hexToR
  var g = parseInt(color.substring(2, 4), 16); // hexToG
  var b = parseInt(color.substring(4, 6), 16); // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#ffffff";
}
