export default function SalesrepHero({ pageData }) {
  return (
    <div
      className="pb-20 p-10 flex flex-col gap-2 bg-no-repeat bg-cover"
      style={{
        backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 1.0), rgba(0, 0, 0, 0.1)), url(${pageData.salesrepImage})`,
      }}
    >
      <div className="flex flex-col md:gap-4 items-center">
        <div className="primary-color md:text-6xl text-xl font-bold">
          Affiliateprogramm
        </div>
        <div className="secondary-color md:text-4xl text-center">
          Ihre attraktiven Vergütungen als Affiliate Partner
        </div>
      </div>
    </div>
  );
}
