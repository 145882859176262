import { Button, Form, Input } from "antd";
import "antd/dist/antd.css";
import { resetPassword } from "../api/userApi";
import { useParams } from "react-router-dom";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import Header from "./common/Header";
import { Alert } from "antd";


export default function ResetPassword({}) {
  let navigate = useNavigate();
  const { resetCode, email } = useParams();

  async function onResetRequest(values) {
    const res = await resetPassword(email, resetCode, values.password);
    if (res.status === 200) {
      notification.success({
        message: "Erfolg!",
        description: "Ihr Passwort wurde erfolgreich zurückgesetzt.",
      });

      navigate("/login");
    }
  }

  return (
    <div className="flex flex-col items-center mt-10">
      <div className="mb-16">
        <Header
          header="Passwort Zurücksetzen"
          subHeader="Geben Sie hier ihr neues Passwort an."
        />
      </div>
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onResetRequest}
        autoComplete="off"
      >
        <Form.Item
          label="Passwort"
          name="password"
          rules={[
            {
              required: true,
              message: "Bitte geben Sie ihr neues Passwort an",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <div className="flex justify-end gap-4 items-center">
          <Button
            style={{
              border: "none",
              backgroundColor: "orange",
              color: "white",
            }}
            type="primary"
            htmlType="submit"
          >
            Passwort Bestätigen
          </Button>
        </div>
      </Form>
      <div className="mt-8 sm:min-w-[36rem]">
        <Alert
          message="Bitte Laden Sie nach dem Zurücksetzen Ihres Passwortes die Webseite neu."
          type="info"
          showIcon
        />
      </div>
    </div>
  );
}
