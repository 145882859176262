import { FilePdfOutlined } from "@ant-design/icons";
import { getFullFileNameFromUrl } from "../../utils/fileUtils";
import Link from "./Link";

export default function FileLink({ url }) {
  return (
    <div className="flex flex-row gap-4 items-center">
      <FilePdfOutlined />
      <Link
        text={getFullFileNameFromUrl(url)}
        onClick={() => window.open(url)}
      />
    </div>
  );
}
