import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { showAboutUs } from "../utils/aboutUsUtils";
import { isLoggedIn } from "../utils/userUtils";
import { MenuOutlined } from "@ant-design/icons";
import { Drawer } from "antd";
import { LoginOutlined, LogoutOutlined } from "@ant-design/icons";
import { Button } from "antd";

export default function Navbar({ pageData }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const TEXT_STYLE = `primary-color md:text-lg text-sm no-underline ml-2`;

  const drawerBodyStyle = {
    display: "flex",
    flexDirection: "column",
    margin: "20px",
    gap: "2rem",
    alignItems: "center",
    fontSize: "1.25rem",
  };

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      {pageData && (
        <nav
          className={`flex text-center items-center justify-between px-6 pt-4 md:flex-row sticky top-0 z-10  ${
            isScrolled ? "shadow-md bg-white/95" : "bg-white"
          }`}
        >
          <div
            className="sm:mb-0 cursor-pointer w-auto h-auto"
            onClick={() => navigate("/")}
          >
            <img
              className="object-contain h-14"
              src={pageData.landingPageLogoFile}
              alt="Logo"
            />
          </div>
          <div className="md:hidden pl-12">
            <MenuOutlined
              onClick={showDrawer}
              className="cursor-pointer text-2xl justify-center items-center flex"
            />
            <Drawer
              placement="right"
              onClose={onClose}
              visible={open}
              contentWrapperStyle={{ width: "auto" }}
              bodyStyle={drawerBodyStyle}
            >
              <a href="/how-it-works" className="primary-color">
                So funktionierts
              </a>

              {showAboutUs(pageData) && (
                <a href="/about-us" className="primary-color">
                  Über Uns
                </a>
              )}
              {pageData.module11 && (
                <a href="/salesrep" className="primary-color">
                  Affiliateprogramm
                </a>
              )}
              {isLoggedIn() ? (
                <a
                  onClick={() => navigate("/logout")}
                  className="primary-color"
                >
                  Abmelden
                </a>
              ) : (
                <a
                  className="primary-color"
                  onClick={() =>
                    window.open(
                      `https://${pageData.investorSubdomain}.investoraccount.eu/login`
                    )
                  }
                >
                  Anlegerkonto
                </a>
              )}
            </Drawer>
          </div>
          <div className="hidden md:flex md:gap-8 gap-2 items-center">
            <a href="/how-it-works" className={TEXT_STYLE}>
              So funktionierts
            </a>
            {showAboutUs(pageData) && (
              <a href="/about-us" className={TEXT_STYLE}>
                Über Uns
              </a>
            )}
            {pageData.module11 && (
              <a href="/salesrep" className={TEXT_STYLE}>
                Affiliateprogramm
              </a>
            )}
            {isLoggedIn() ? (
              <Button
                className={`${TEXT_STYLE} !flex items-center border-primary`}
                size="large"
                onClick={() => navigate("/logout")}
                icon={<LogoutOutlined />}
              >
                Abmelden
              </Button>
            ) : (
              <Button
                className={`${TEXT_STYLE} !flex items-center border-primary`}
                size="large"
                onClick={() =>
                  window.open(
                    `https://${pageData.investorSubdomain}.investoraccount.eu/login`
                  )
                }
                icon={<LoginOutlined />}
              >
                Anlegerkonto
              </Button>
            )}
          </div>
        </nav>
      )}
    </>
  );
}
