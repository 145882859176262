import { renderHtmlFromRaw } from "../../utils/htmlUtils";
import Divider from "../Divider";

export default function SalesrepAbout({ pageData }) {
  return (
    <div>
      <Divider text="Affiliateprogramm" />
      <div className="md:flex gap-12 ml-8 mr-8 md:pl-20 md:pr-20 items-center text-center md:text-justify">
        <div className="logo-small">
          <img src={pageData.salesrepLogo} />
        </div>
        <div className="text-xl">
          {renderHtmlFromRaw(pageData.salesrepDescription)}
        </div>
      </div>
    </div>
  );
}
