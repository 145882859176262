/**
 * Example:
 * https://smartefunding.com/product/pdf-test.pdf -> pdf-test.pdf
 */
export function getFullFileNameFromUrl(url) {
  url = url.split("/").at(-1);
  return getFileNameSummary(url);
}

function getFileNameSummary(fileName) {
  if (fileName.length > 30) {
    return fileName.substring(0, 30) + "...";
  }
  return fileName;
}
