import { Button, Form, Input } from "antd";
import "antd/dist/antd.css";
import { resetPasswordRequest } from "../api/userApi";
import { notification } from "antd";
import Header from "./common/Header";

export default function ResetPasswordRequest({}) {
  async function onResetRequest(values) {
    const res = await resetPasswordRequest(values.email);
    if (res.status === 200) {
      notification.info({
        message: "Information !",
        description:
          "Sie haben eine Email erhalten, durch die Sie ihr Passwort zurücksetzen können.",
      });
    }
  }

  return (
    <div className="flex flex-col items-center mt-10">
      <div className="mb-16">
        <Header
          header="Passwort Zurücksetzen"
          subHeader="Geben Sie hier Ihre Email Adresse an um ihr Passwort zurückzusetzen."
        />
      </div>
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onResetRequest}
        autoComplete="off"
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Bitte geben Sie Ihre Email Adresse an",
              type: "email",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <div className="flex justify-end gap-4 items-center">
          <Button
            style={{
              border: "none",
              backgroundColor: "orange",
              color: "white",
            }}
            type="primary"
            htmlType="submit"
          >
            Senden
          </Button>
        </div>
      </Form>
    </div>
  );
}
