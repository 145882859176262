import { useEffect } from "react";
import { logout } from "../utils/userUtils";
import { useNavigate } from "react-router-dom";

export default function Logout({ pageData }) {
  let navigate = useNavigate();

  useEffect(() => {
    logout();

    navigate("/"); // return home
  }, []);

  return <div></div>;
}
