import { renderHtmlFromRaw, isEmptyHTML } from "../../utils/htmlUtils";
import Gallery from "../Gallery";

export default function ProductBase({ product }) {
  return (
    <>
      {product && (
        <div className="flex p-4 gap-8 m-4 mt-5 justify-center md:flex-row flex-col items-center md:items-start overflow-x-hidden">
          <div className="flex flex-col gap-4">
            <div>
              <Gallery product={product} />
            </div>
            {!isEmptyHTML(product.welcome) && (
              <div className="shadow-box">
                <div className="font-bold text-lg">Herzlich Willkommen</div>
                {renderHtmlFromRaw(product.welcome)}
              </div>
            )}
            {!isEmptyHTML(product.description) && (
              <>
                <div className="shadow-box">
                  <div className="font-bold text-lg">Kurzbeschreibung</div>
                  {renderHtmlFromRaw(product.description)}
                </div>
              </>
            )}
            {!isEmptyHTML(product.managementTeamDescription) && (
              <div className="shadow-box">
                <div className="font-bold text-lg">Management/Team</div>
                {renderHtmlFromRaw(product.managementTeamDescription)}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
