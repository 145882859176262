import Divider from "./Divider";

export default function ExplanationVideos() {
  return (
    <div>
      <Divider text="Erklärungsvideos" />
      <div className="flex gap-4 justify-evenly mt-4 flex-col md:flex-row items-center">
        <div className="flex-col">
          <div className="aspect-video">
            <figure>
              <iframe
                className="h-[200px] w-[350px] rounded-lg"
                src="https://www.youtube.com/embed/NXqC5agIwh8"
                title="MetaMask Anleitung"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
              <figcaption className="text-center">
                Anleitung zur Einrichtung <br /> eines MetaMask Wallets
              </figcaption>
            </figure>
          </div>
        </div>
        <div className="flex-col">
          <div className="aspect-video">
            <figure>
              <iframe
                className="h-[200px] w-[350px] rounded-lg object-fit"
                src="https://www.youtube.com/embed/LrzuboexlZA"
                title="Zeichnungsprozess Anleitung"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
              <figcaption className="text-center">
                Anleitung für ein Zeichnungsprozess <br /> noch dem
                Eigenemission Privileg
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
}
