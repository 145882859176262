import { toEuro } from "../../utils/currencyUtils";
import { securityTypeToName } from "../../utils/productUtils";
import { stringToDateObject, diffInYears, diffInMonths } from "../../utils/dateUtils";
import { paymentsPerYearToWord } from "../../utils/interestUtils";

export default function ProductTable({ product }) {
  return (
    <>
      {product && (
        <div>
          <table className="mt-4 ml-4 mr-4">
            <tr>
              <td>Anlageklasse</td>
              <td>
                {securityTypeToName(product.securityType, product.isTokenized)}
              </td>
            </tr>
            <tr>
              <td>Laufzeit</td>
              <td>
                {diffInMonths(
                  stringToDateObject(product.contractStart),
                  stringToDateObject(product.contractEnd)
                )}{" "}
                Monat(e)
              </td>
            </tr>
            <tr>
              <td>Mindestzeichnung</td>
              <td>{toEuro(product.minInvestment)}</td>
            </tr>
            {product.isProjectDivident ? (
              <tr>
                <td>Prognostizierte Dividende</td>
                <td>{product.forcastedProjectInterest}% p.a.</td>
              </tr>
            ) : (
              <tr>
                <td>Zins</td>
                <td>{product.interest}% p.a.</td>
              </tr>
            )}
            <tr>
              <td>
                {product.isProjectDivident ? "Dividenten" : "Zins"}
                ausschüttungen
              </td>
              <td>{paymentsPerYearToWord(product.paymentsPerYear)}</td>
            </tr>
            {product.withProfitSharing && (
              <>
                <tr>
                  <td>Gewinnbeteiligung</td>
                  <td>bis zu {product.profitSharingPercentage}% p.a.</td>
                </tr>
                <tr>
                  <td>Gewinnausschüttung</td>
                  <td>endfällig</td>
                </tr>
              </>
            )}
            <tr>
              <td>Tilgung</td>
              <td>endfällig</td>
            </tr>
            <tr>
              <td>
                {product.alreadyPaid
                  ? "Finanziertes Volumen"
                  : "Finanzierungsvolumen"}
              </td>
              <td>{toEuro(product.volume)}</td>
            </tr>
            {product.alreadyPaid ? (
              <tr>
                <td>Bereits zurückgezahlt</td>
                <td>{toEuro(product.alreadyPaid)}</td>
              </tr>
            ) : null}
          </table>
        </div>
      )}
    </>
  );
} 
