import React from "react";
import ReactPlayer from "react-player/lazy";
import screen from "../static/screen.jpg";

export const Video = ({ pageData }) => {
  return (
    <div className="flex items-center justify-center my-8 relative">
      <img src={screen} alt="Unser Video" />
      <div className="absolute top-0 sm:top-5 left-0 w-full h-full flex items-start justify-center">
        <ReactPlayer
          className="mx-6 my-8 !h-48 sm:!h-[360px]"
          url={pageData?.videoLink}
          playing={true}
          loop={true}
          muted={true}
          controls={true}
        />
      </div>
    </div>
  );
};
