import { Button } from "antd";
import "antd/dist/antd.css";
import { notification } from "antd";
import Header from "./common/Header";
import { useParams } from "react-router-dom";
import { requestPPC } from "../api/productApi";
import { useNavigate } from "react-router-dom";

export default function RequestProductCode() {
  let navigate = useNavigate();
  const { productId } = useParams();

  async function requestCode() {
    const res = await requestPPC(productId);

    if (res.status === 200) {
      notification.success({
        message: "Erfolg !",
        description: "Private Placement Code wurde erfolgreich angefordert",
      });
    }

    navigate(`/product/${productId}`);
  }

  return (
    <div className="p-4">
      <div className="flex flex-col items-center mt-10">
        <div className="mb-16 max-w-4xl">
          <Header
            header="Private Placement Produkt Code Anfrage"
            subHeader="Das von ihnen ausgewählte Finanzprodukt ist ein Private Placement. Bevor Sie weitere Produkt Informationen einsehen dürfen, müssen Sie einen Private Placement Code anfordern."
          />
        </div>
        <Button
          style={{
            border: "none",
            backgroundColor: "orange",
            color: "white",
          }}
          onClick={() => requestCode()}
        >
          Code Anfordern
        </Button>
      </div>
    </div>
  );
}
