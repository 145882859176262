import { Button, Form, Input } from "antd";
import "antd/dist/antd.css";
import { login } from "../api/userApi";
import Link from "./common/Link";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import Header from "./common/Header";
import { loginResponseHandler } from "../utils/loginUtils";

export default function Login() {
  let navigate = useNavigate();

  async function onLogin(values) {
    const res = await login(values.email, values.password);

    if (res.status === 200) {
      loginResponseHandler(res, navigate);
    } else {
      notification.error({
        message: "Fehler!",
        description:
          "Sie haben eine falsche Email/Passwort Kombination angegeben.",
      });
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="flex flex-col items-center mt-10">
      <div className="mb-5">
        <Header
          header="Anmelden / Registrieren"
          subHeader="Melden Sie sich hier an oder registrieren Sie sich neu.
          Besteht bereits eine Registrierung, reicht die einfache Anmeldung, um einen Code anzufordern, aus. Danach ist keine weitere Registrierung mehr notwendig. Sie können jederzeit per Anmeldung Ihren Account einsehen und dann das von Ihnen ausgewählte Produkt zeichnen."
        />
      </div>
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onLogin}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Bitte geben Sie eine valide Email Adresse an",
              type: "email",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Passwort"
          name="password"
          rules={[
            {
              required: true,
              message: "Bitte geben Sie ihr Passwort an",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <div className="flex justify-end gap-10 ">
          <div className="flex flex-col gap-2">
            <Link onClick={() => navigate("/signup")} text="Registrieren" />
            <Link
              onClick={() => navigate("/reset-password-request")}
              text="Passwort vergessen?"
            />
          </div>
          <Button
            style={{
              border: "none",
              backgroundColor: "orange",
              color: "white",
            }}
            type="primary"
            htmlType="submit"
          >
            Anmelden
          </Button>
        </div>
      </Form>
    </div>
  );
}
