export function paymentsPerYearToWord(paymentsPerYear) {
  switch (paymentsPerYear) {
    case 12:
      return "Monatlich";
    case 4:
      return "Alle 3 Monate";
    case 2:
      return "Alle 6 Monate";
    case 1:
      return "Alle 12 Monate";
    case 0:
      return "Endfällig";
  }
}
