import { renderHtmlFromRaw, isEmptyHTML } from "../utils/htmlUtils";
import Divider from "./Divider";

export default function AboutUs({ pageData }) {
  return (
    <>
      {pageData && (
        <div className="flex flex-col mb-8 mr-8 ml-8 p-8 justify-center items-center overflow-x-hidden">
          <div className="max-w-full lg:max-w-7xl flex flex-col gap-16">
            {!isEmptyHTML(pageData.aboutUs) && (
              <div>
                <Divider text="Über uns" />
                <div>{renderHtmlFromRaw(pageData.aboutUs)}</div>
              </div>
            )}
            {!isEmptyHTML(pageData.ourMission) && (
              <div>
                <Divider text="Unsere Mission" />
                <div>{renderHtmlFromRaw(pageData.ourMission)}</div>
              </div>
            )}
            {!isEmptyHTML(pageData.ourVision) && (
              <div>
                <Divider text="Unsere Vision" />
                <div>{renderHtmlFromRaw(pageData.ourVision)}</div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
