import Divider from "./Divider";
import { ReactComponent as Globe } from "../static/globe.svg";
import { ReactComponent as Lock } from "../static/lock.svg";
import { ReactComponent as Chart } from "../static/chart.svg";
import { ReactComponent as Umbrella } from "../static/umbrella.svg";
import { getCookie } from "../utils/cookieUtils";

export default function InvestorPerks() {
  const primaryColor = getCookie("primaryColor");

  return (
    <div className="bg-gray-100 w-full flex flex-col justify-center items-center">
      <Divider text="Ihre Vorteile als Investor" />
      <div className="pl-8 pr-8 pb-8 pt-4 md:grid grid-cols-4 gap-4 flex flex-col gap-8 max-w-7xl justify-start md:items-start items-center">
        <div className="flex flex-col items-center">
          <div className="mb-6">
            <Globe width={60} height={60} fill={primaryColor} />
          </div>
          <div className="font-bold">Nachhaltige Anlagen</div>
          <div className="font-light text-center">
            durch eine sorgfältige Auswahl an Angeboten mit einem Fokus auf
            ethische, soziale und ökologische Aspekte.
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="mb-6">
            <Lock width={60} height={60} fill={primaryColor} />
          </div>
          <div className="font-bold">Sichere Transaktion</div>
          <div className="font-light text-center">
            durch aufsichtsrechtlich konforme Prozesse in eine regulierte und
            auf Plausibilität geprüfte Kapitalanlagen.
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="mb-6">
            <Chart width={60} height={60} fill={primaryColor} />
          </div>
          <div className="font-bold">Profitable Renditen</div>
          <div className="font-light text-center">
            durch direkte Investition in Vermögenswerte wie ein Institutioneller
            Investor.
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="mb-6">
            <Umbrella width={60} height={60} fill={primaryColor} />
          </div>
          <div className="font-bold">Risikostreuung</div>
          <div className="font-light text-center">
            durch niedrige Mindestzeichnungen möglich.
          </div>
        </div>
      </div>
    </div>
  );
}
