import { Progress } from "antd";
import { getCookie } from "../../utils/cookieUtils";

export default function ProgressBar({ progressInPercent }) {
  return (
    <div>
      <Progress
        percent={progressInPercent}
        strokeColor={getCookie("primaryColor")}
      />
    </div>
  );
}
