import React, { useEffect, useState } from "react";
import { getChatbot } from "../../api/chatbotApi";
import { getLandingPageData } from "../../api/superuserApi";
import { getCookie } from "../../utils/cookieUtils";
import { whiteOrBlack } from "../../utils/colorUtils";


const ChatbotMain = (props) => {
  const [chatbotItems, setChatbotItems] = useState([]);
  const [welcomeMessage, setWelcomeMessage] = useState("");

  useEffect(() => {
    chatbotInit();
  }, []);

  async function chatbotInit() {
    try {
      const res = await getLandingPageData();
      const chatbot = await getChatbot(res.id);

      if (chatbot) {
        setWelcomeMessage(chatbot.welcomeMessage);
        setChatbotItems(chatbot.entrys || []);
      } else {
        setChatbotItems([]);
      }
    } catch (error) {
      setChatbotItems([]);
    }
  }

  const options = chatbotItems.map((item) => {
    return {
      text: item.question,
      handler: () => props.actionProvider.handleAnswers(item.answer),
      id: item.id,
    };
  });

  const optionsMarkup = options.map((option) => (
    <button
      style={{
        borderColor: getCookie("secondaryColor"),
        color: getCookie("secondaryColor"),
      }}
      className="learning-option-button px-6 py-3 my-2 flex flex-col rounded-full border font-bold"
      key={option.id}
      onClick={option.handler}
    >
      {option.text}
    </button>
  ));

  return (
    <>
    {welcomeMessage? (
     <div
        style={{
          backgroundColor: getCookie("secondaryColor"),
          color: whiteOrBlack(getCookie("secondaryColor")),
        }}
        className="rounded-lg p-4"
      >
        {welcomeMessage}
      </div>
      ): null}   
      <div className="flex flex-wrap justify-center mt-4">{optionsMarkup}</div>
    </>
  );
};

export default ChatbotMain;
