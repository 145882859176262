import Button from "../common/Button";

export default function SalesrepBrokeraccountButton({ pageData }) {
  return (
    <Button
      text="Zum Affiliateprogramm"
      onClick={() =>
        window.open(`https://${pageData.affiliateSubdomain}.brokeraccount.eu`)
      }
    />
  );
}
