import React from "react";
import { createClientMessage } from "react-chatbot-kit";

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
    
  const handleAnswers = ( answer ) => {
    const botMessage = createChatBotMessage(answer, {
      widget: "chatbotAnswers",
    });
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleDefault = () => {
    const botMessage = createChatBotMessage(
      "Bitte Wählen Sie oben eine Frage aus!"
    );
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleAnswers,
            handleDefault,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;
