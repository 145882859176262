import axios from "axios";

export async function getLandingPageData() {
  return axios
    .get(`/superuser/${window.location.hostname}/landing-page-data`)
    .then((res) => {
      return res.data.superuser;
    })
    .catch((err) => {
      console.log("Something went wrong " + err);
      return err;
    });
}
