import { Button, Form, Input } from "antd";
import "antd/dist/antd.css";
import { activate } from "../api/userApi";
import { notification } from "antd";
import Header from "./common/Header";

export default function Activate({ pageData, setStep, user, setUser }) {
  async function onActivate(values) {
    const res = await activate(user.email, values.activationCode);

    if (res.status === 200) {
      setStep("set-password");
      setUser({ ...user, activationCode: values.activationCode });
    } else {
      notification.error({
        message: "Fehler !",
        description: "Registrierung ist fehlgeschlagen",
      });
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <div className="flex flex-col items-center mt-10">
        <div className="mb-16">
          <Header
            header="Bestätigen Sie Ihre Email"
            subHeader="Geben Sie bitte hier den Ihnen soeben via Email übersandten Code ein um Ihre Email zu verfizieren"
          />
        </div>
        <Form
          name="basic"
          labelCol={{
            span: 0,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onActivate}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Registrierungs Code"
            name="activationCode"
            rules={[
              {
                required: true,
                message: "Bitte geben Sie Ihren Aktivierungscode an",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <div className="flex justify-end gap-4 items-center">
            <Button
              style={{
                border: "none",
                backgroundColor: "orange",
                color: "white",
              }}
              type="primary"
              htmlType="submit"
            >
              Bestätigen
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
