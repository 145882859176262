import axios from "axios";

export async function getProductById(id) {
  return axios
    .get(`/product/${id}/public-product`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("Something went wrong " + err);
      return err;
    });
}

export async function requestPPC(id) {
  return axios
    .get(`/product/${id}/request-ppc`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("Something went wrong " + err);
      return err;
    });
}

export async function getProducts() {
  return axios
    .get(`/product/${window.location.hostname}/public-products`)
    .then((res) => {
      return res.data.product;
    })
    .catch((err) => {
      console.log("Something went wrong " + err);
      return err;
    });
}
