import { getCookie } from "./cookieUtils";

// get a subdomain email by appending the email to its corresponding subdomain
export function getSubdomainEmail(email) {
  return email + "+" + getSubdomain();
}

export function getSubdomain() {
  return getCookie("investorSubdomain");
}
