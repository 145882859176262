import { Button, Form, Input, Select } from "antd";
import "antd/dist/antd.css";
import { signup } from "../api/userApi";
import { notification } from "antd";
import { useSalesrepUuid } from "../utils/cookieUtils";

const { Option } = Select;

export default function Signup({ pageData, setStep, setUser }) {
  const salesrep_uuid = useSalesrepUuid();

  async function onSignup(values) {
    const res = await signup(
      {
        country: values.country,
        salutation: values.salutation,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        company: values.company,

        // superuser
        superuser: pageData.id,
      },
      salesrep_uuid
    );

    if (res.status === 201) {
      setUser(res.data.investor.user);
      setStep("activate");
    } else {
      notification.error({
        message: "Fehler !",
        description: "Registrierung ist fehlgeschlagen",
      });
    }
  }

  return (
    <div className="flex flex-col items-center mt-10">
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onSignup}
        autoComplete="off"
      >
        <Form.Item
          label="Land"
          name="country"
          rules={[
            {
              required: true,
              message: "Bitte geben Sie ihr Herkuftsland an",
            },
          ]}
        >
          <Select defaultValue="">
            <Option value="Deutschland">Deutschland</Option>
            <Option value="Österreich">Österreich</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Anrede"
          name="salutation"
          rules={[
            {
              required: true,
              message: "Bitte geben Sie Ihre Anrede an",
            },
          ]}
        >
          <Select defaultValue="">
            <Option value="Herr">Herr</Option>
            <Option value="Frau">Frau</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Vorname"
          name="firstName"
          rules={[
            {
              required: true,
              message: "Bitte geben Sie Ihre Vorname an",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Nachname"
          name="lastName"
          rules={[
            {
              required: true,
              message: "Bitte geben Sie ihr Nachname an",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Bitte geben Sie Ihre Email Adresse an",
              type: "email",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Unternehmen"
          name="company"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <div className="flex justify-end gap-4 items-center">
          <Button
            style={{
              border: "none",
              backgroundColor: "orange",
              color: "white",
            }}
            type="primary"
            htmlType="submit"
          >
            Registrieren
          </Button>
        </div>
      </Form>
    </div>
  );
}
