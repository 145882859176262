import ExternalLink from "./common/ExternalLink";
import Link from "./common/Link";
import { useNavigate } from "react-router-dom";
import { showAboutUs } from "../utils/aboutUsUtils";

const HEADER_STYLE = "text-white text-base font-bold mb-4";
const TEXT_SECTION_STYLE = "flex flex-col gap-2";

export default function Footer({ pageData }) {
  let navigate = useNavigate();
  return (
    <>
      {pageData && (
        <div className="w-100%">
          <div className="bg-[#262626] flex justify-around p-8 flex-col md:flex-row gap-4">
            <div>
              <div className={HEADER_STYLE}>Unternehmen</div>
              <div className={TEXT_SECTION_STYLE}>
                <Link
                  text="So funktionierts"
                  onClick={() => navigate("how-it-works")}
                />
                {showAboutUs(pageData) && (
                  <Link text="Über uns" onClick={() => navigate("about-us")} />
                )}
                {pageData.module11 && (
                  <Link
                    text="Affiliateprogramm"
                    onClick={() => navigate("salesrep")}
                  />
                )}
              </div>
            </div>
            <div>
              <div className={HEADER_STYLE}>Rechtliches</div>
              <div className={TEXT_SECTION_STYLE}>
                {pageData.agb && <ExternalLink text="AGB" url={pageData.agb} />}
                {pageData.dataPrivacy && (
                  <ExternalLink text="Datenschutz" url={pageData.dataPrivacy} />
                )}
                {pageData.imprint && (
                  <ExternalLink text="Impressum" url={pageData.imprint} />
                )}
              </div>
            </div>
            <div>
              <div className={HEADER_STYLE}>Kontakt</div>
              <div>
                {pageData.contact && (
                  <ExternalLink text="Kontakt" url={pageData.contact} />
                )}
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="text-white">Powered by:</div>
              <img
                className="w-60"
                src="https://smartefunding.s3.eu-central-1.amazonaws.com/static/eProgramming-Logo-transparent.png"
              />
            </div>
          </div>
          <div className="bg-[#222222]">
            <div className="text-gray-200 font-bold p-4">
              © 2023 eProgramming - Alle Rechte vorbehalten
            </div>
          </div>
        </div>
      )}
    </>
  );
}
