import axios from "axios";


export async function getChatbot(userId) {
    return axios
    .get(`/chatbot/user/${userId}`)
    .then((res) => {
        return res.data;
    })
    .catch((err) => {
        console.log("Something went wrong " + err);
        return err;
    })
}