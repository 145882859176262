import axios from "axios";
import { getSubdomainEmail } from "../utils/subdomainUtils";

export async function login(email, password, withSubdomainEmail = true) {
  if (withSubdomainEmail) {
    email = getSubdomainEmail(email);
  }

  // TODO: get subdomian
  return axios
    .post(`/login`, {
      email: email,
      password: password,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("Something went wrong " + err);
      return err;
    });
}

export function signup(data, salesrep_uuid) {
  data["email"] = getSubdomainEmail(data["email"]);

  let url = "/investor/signup";

  if (salesrep_uuid) {
    url += `?salesrep_uuid=${salesrep_uuid}`;
  }

  // TODO: get subdomian
  return axios
    .post(url, data)
    .then((res) => {
      // if (res.status === 409) {
      //   toast.error("Nutzer mit diese Email existiert bereits!");
      // } else if (res?.status !== 201) {
      //   toast.error(API_ERROR);
      // }
      return res;
    })
    .catch((err) => {
      console.log("Something went wrong " + err);
      return err;
    });
}

export function activate(email, activationCode) {
  // email = getSubdomainEmail(email);

  return axios
    .post("/activate", {
      email: email,
      activationCode: activationCode,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return null;
    });
}

export function setPassword(email, newPassword, activationCode) {
  // email = getSubdomainEmail(email);

  return axios
    .post(`/set-password`, {
      email: email,
      password: newPassword,
      activationCode: activationCode,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}

export function resetPasswordRequest(email) {
  email = getSubdomainEmail(email);

  return axios
    .post(`/reset-password-request`, {
      email: email,
      url: window.location.hostname,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("Something went wrong " + err);
      return err;
    });
}

export function resetPassword(email, resetCode, newPass) {
  // IMPORTANT: not needed here!
  // email = getSubdomainEmail(email);

  return axios
    .post(`/reset-password`, {
      email: email,
      reset_code: resetCode,
      password: newPass,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log("Something went wrong " + err);
      return err;
    });
}
