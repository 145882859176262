import {
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  MailOutlined,
} from "@ant-design/icons";

const PROPS = {
  color: "gray",
  fontSize: "1.0rem",
  cursor: "pointer",
};

const ensureAbsoluteUrl = (url) => {
  // Check if the URL starts with http:// or https://
  if (!/^https?:\/\//i.test(url)) {
    return `https://${url}`;
  }
  return url;
};

export default function SocialMedia({ pageData }) {
  return (
    <>
      {pageData && (
        <div className="flex gap-3 items-center justify-center">
          {pageData.instagramLink && (
            <InstagramOutlined
              style={PROPS}
              onClick={() =>
                window.open(ensureAbsoluteUrl(pageData.instagramLink))
              }
            />
          )}
          {pageData.facebookLink && (
            <FacebookOutlined
              style={PROPS}
              onClick={() => window.open(ensureAbsoluteUrl(pageData.facebookLink))}
            />
          )}
          {pageData.linkedinLink && (
            <LinkedinOutlined
              style={PROPS}
              onClick={() => window.open(ensureAbsoluteUrl(pageData.linkedinLink))}
            />
          )}
          {pageData.emailLink && (
            <MailOutlined
              style={PROPS}
              onClick={() => window.open(ensureAbsoluteUrl(pageData.emailLink))}
            />
          )}
        </div>
      )}
    </>
  );
}
