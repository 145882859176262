import { useState } from "react";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";

export default function QuestionAnswer({ question, answer }) {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <div>
      <div
        className="bg-gray-100 p-4 border border-gray-300 flex items-center gap-2 cursor-pointer w-full"
        onClick={() => setCollapsed(!collapsed)}
      >
        {collapsed ? <PlusOutlined /> : <MinusOutlined />}
        <div className="w-full">{question}</div>
      </div>
      {!collapsed && <div className="mt-2 ml-2">{answer}</div>}
    </div>
  );
}
