import { CheckCircleFilled, LikeFilled, SmileFilled } from "@ant-design/icons";
import Divider from "./Divider";

const ICONS_PROPS = {
  fontSize: "3rem",
  color: "#114063",
};

const TEXT_PROPS = "mt-4 text-lg";

export default function ZeichnungInfo() {
  return (
    <div className="">
      <Divider text="Von der Information bis zum Profit" />
      <div className="flex justify-around items-center text-center mt-4 flex-col md:flex-row gap-4">
        <div>
          <LikeFilled className="primary-color" style={ICONS_PROPS} />
          <div className={TEXT_PROPS}>1. Anlage prüfen</div>
        </div>
        <div>
          <CheckCircleFilled className="primary-color" style={ICONS_PROPS} />
          <div className={TEXT_PROPS}>2. Kapitalanlage zeichnen</div>
        </div>
        <div>
          <SmileFilled className="primary-color" style={ICONS_PROPS} />
          <div className={TEXT_PROPS}>
            3. Von attraktiven Renditen profitieren
          </div>
        </div>
      </div>
    </div>
  );
}
