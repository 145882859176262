import CookieConsent from "react-cookie-consent";

export default function CookieBanner() {
  return (
    <>
      <CookieConsent buttonText="Ich stimme zu">
        Diese Seite benutzt Cookies und andere Technologien, damit unsere Sites
        zuverlässig und sicher laufen, wir deren Performance auf dem Schirm
        behalten und um dir relevante Inhalte und personalisierte Werbung zu
        zeigen. Damit das funktioniert, sammeln wir Daten über unsere Nutzer und
        wie sie unsere Angebote auf welchen Geräten nutzen.
      </CookieConsent>
    </>
  );
}
