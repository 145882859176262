import { deleteCookie, getCookie } from "./cookieUtils";

// checks if an user is logged in
export function isLoggedIn() {
  // there is a user logged in if an accessToken exists
  return getCookie("accessToken");
}

export function logout() {
  deleteCookie("accessToken");
  deleteCookie("refreshToken");

  // reload site!
  // window.location.reload();
}
