import ImageGallery from "react-image-gallery";
import { createImageArray } from "../utils/productUtils";

export default function Gallery({ product }) {
  return (
    <>
      {product && (
        <ImageGallery
          showFullscreenButton={false}
          showPlayButton={false}
          items={createImageArray(product)}
        />
      )}
    </>
  );
}
