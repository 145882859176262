import {
  EditFilled,
  SettingFilled,
  SmileOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Divider from "../Divider";

export default function SalesrepHowItWorks() {
  return (
    <div className="bg-gray-100 w-full flex flex-col justify-center items-center">
      <Divider text="So funktionierts" />
      <div className="pl-8 pr-8 pb-8 pt-4 md:grid grid-cols-4 gap-4 flex flex-col gap-8 max-w-7xl justify-start md:items-start items-center">
        <div className="flex flex-col items-center">
          <div className="mb-6">
            <UserOutlined
              className="primary-color"
              style={{ fontSize: "4rem" }}
            />
          </div>
          <div className="font-light text-center">
            Mit der Anmeldung zu unserem Partnerprogramm schließen Sie einen
            Tippgebervertrag als Affiliate Partner ab. Nach Ihrer Anmeldung
            erhalten Sie einen Werbelink sowie konversionsstarke und
            ansprechende Werbemittel.
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="mb-6">
            <SettingFilled
              className="primary-color"
              style={{ fontSize: "4rem" }}
            />
          </div>
          <div className="font-light text-center">
            Platzieren Sie unseren „Online Marketern“ Ref-Link auf Ihrer Website
            oder Ihrem Blog. Posten sie es in den Sozialen Medien oder versenden
            Sie es via E-Mail. Ihrer Kreativität sind hier keine Grenzen
            gesetzt.
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="mb-6">
            <TeamOutlined
              className="primary-color"
              style={{ fontSize: "4rem" }}
            />
          </div>
          <div className="font-light text-center">
            Von Ihnen geworbene Interessenten kommen über ein durch Sie
            eingesetztes Werbemittel auf unsere Plattform. Der Interessent
            registriert sich und investiert in eines unserer Anlageangebote.
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div className="mb-6">
            <SmileOutlined
              className="primary-color"
              style={{ fontSize: "4rem" }}
            />
          </div>
          <div className="font-light text-center">
            Für jeden von Ihnen geworbenen Anleger erhalten Sie attraktive
            Provisionen. Die Höhe der Provisionen geht aus Ihrem
            Tippgebervertrag hervor.
          </div>
        </div>
      </div>
    </div>
  );
}
