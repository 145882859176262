import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Body from "./components/Body";

export default function App() {
  return (
    <BrowserRouter>
      <Body />
    </BrowserRouter>
  );
}
