import { useLocation } from "react-router-dom";

const PREFIX = "sef-landing-page-";

export function deleteCookie(cname) {
  cname = PREFIX + cname;

  var expires = "Thu, 01 Jan 1970 00:00:00 GMT";
  document.cookie = cname + "= ;" + expires + ";path=/";
}

export function getCookie(cname) {
  cname = PREFIX + cname;

  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function setCookie(cname, cvalue, exdays) {
  cname = PREFIX + cname;

  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

/**
 * store salesrep_uuid if found in url params,
 * otherwise get it from cookie if it exists.
 */
export function useSalesrepUuid() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  var salesrepUuid = query.get("salesrep_uuid");
  if (salesrepUuid) {
    setCookie("salesrep_uuid", salesrepUuid);
  } else {
    salesrepUuid = getCookie("salesrep_uuid");
  }
  return salesrepUuid;
}
