import { whiteOrBlack } from "../../utils/colorUtils";
import { getCookie } from "../../utils/cookieUtils";

export default function Menu({ items, index, setIndex }) {
  return (
    <div
      className="grid grid-cols-2 grid-rows-2 place-items-center p-4 gap-8 w-full sm:flex sm:p-1 justify-center items-center"
      style={{
        color: whiteOrBlack(getCookie("primaryColor")),
        backgroundColor: getCookie("primaryColor"),
      }}
    >
      {items.map((item) => {
        return (
          <div
            className={`text-[15px] xs:text-lg cursor-pointer ${
              item.key === index && "font-bold underline"
            }`}
            onClick={() => setIndex(item.key)}
          >
            {item.name}
          </div>
        );
      })}
    </div>
  );
}
