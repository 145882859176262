import { useState } from "react";
import Activate from "./Activate";
import SetPassword from "./SetPassword";
import Signup from "./Signup";

export default function SignupOverview({ pageData }) {
  const [user, setUser] = useState();
  const [step, setStep] = useState("signup");

  return (
    <div>
      {step === "signup" && (
        <Signup pageData={pageData} setStep={setStep} setUser={setUser} />
      )}
      {step === "activate" && (
        <Activate
          pageData={pageData}
          setStep={setStep}
          user={user}
          setUser={setUser}
        />
      )}
      {step === "set-password" && (
        <SetPassword pageData={pageData} setStep={setStep} user={user} />
      )}
    </div>
  );
}
