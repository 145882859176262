import { useEffect, useState } from "react";
import ProductBase from "./ProductBase";
import { useParams } from "react-router-dom";
import { getProductById } from "../../api/productApi";
import { useNavigate } from "react-router-dom";
import ProductBuisnessModel from "./ProductBuisnessModel";
import ZeichnenCard from "../ZeichnenCard";
import ProductParticipationModel from "./ProductParticipationModel";
import ProductQuestions from "./ProductQuestions";
import Menu from "../common/Menu";
import Loading from "../common/Loading";

const items = [
  { name: "Projektbeschreibung", key: "base" },
  { name: "Geschäftsmodell", key: "buisness-model" },
  { name: "Beteiligungsangebot", key: "participation-model" },
  { name: "Anlegerfragen", key: "questions" },
];

export default function ProductOverview({ pageData }) {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [index, setIndex] = useState("base");
  const { productId } = useParams();
  const [product, setProduct] = useState();

  useEffect(() => {
    onInit();
  }, []);

  async function onInit() {
    setIsLoading(true);
    const res = await getProductById(productId);
    if (res.status === 200) {
      setProduct(res.data.product);
    }
    if (res.status === 403) {
      // happens when it is a private placement and the investor has no code
      navigate(`request-ppc`);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [index]);


  return (
    <>
      <Loading isLoading={isLoading} />
      {!product && !isLoading && (
        <div className="flex items-center justify-center text-lg">
          Kein Produkt wurde gefunden!
        </div>
      )}
      {product && (
        <>
          <div className="flex items-center justify-center w-full mt-2 md:mt-0 pt-2 pb-2 sticky top-16 z-10">
            <Menu index={index} setIndex={setIndex} items={items} />
          </div>
          <div className="flex justify-center mb-8 flex-col lg:flex-row items-center lg:items-start">
            <div className="flex flex-col items-center max-w-[50rem] relative">
              <div className="overflow-y-auto">
                {index === "base" && <ProductBase product={product} />}
                {index === "buisness-model" && (
                  <ProductBuisnessModel product={product} />
                )}
                {index === "participation-model" && (
                  <ProductParticipationModel product={product} />
                )}
                {index === "questions" && (
                  <ProductQuestions product={product} />
                )}
              </div>
            </div>
            <div className="flex mt-[2.2rem] md:mr-8 sticky top-36">
              <ZeichnenCard product={product} pageData={pageData} />
            </div>
          </div>
        </>
      )}
    </>
  );
}
