import Button from "./common/Button";
import Divider from "./Divider";
import ExplanationVideos from "./ExplanationVideos";
import ZeichnungInfo from "./ZeichnungInfo";
import { useNavigate } from "react-router-dom";

export default function HowItWorks() {
  let navigate = useNavigate();

  return (
    <div className="flex flex-col mb-8 mx-8 p-8 justify-center items-center">
      <div className="max-w-7xl flex flex-col gap-16 text-center">
        <ExplanationVideos />
        <ZeichnungInfo />
        <div>
          <Divider text="Schritt 1: Anlage prüfen" />
          <div className="px-6">
            Informieren Sie sich in Ruhe über unser Anlageangebot. Wir legen
            großen Wert auf Transparenz und Sicherheit. Klicken Sie einfach auf
            "mehr Informationen" unter unserem Angebot und erhalten Sie
            ausführliche Informationen über das Geschäftsmodell, den Markt sowie
            über unser Beteiligungsangebot. Darüber hinaus finden Sie eine
            umfangreiche FAQ Zusammenstellung. So können Sie selbst das
            Beteiligungsangebot einschätzen und ganz in Ruhe über die
            Investitionshöhe und über den Zeitpunkt Ihrer Zeichnung entscheiden.
          </div>
          <div className="mt-2 px-6">
            Aus rechtlichen Gründen des Private Placement existieren lediglich
            149 Codes pro EU Staat, um die Produktinformationen einzusehen und
            zu zeichnen. Sollten Sie sich also für ein Private Placement, das
            nur 149 Anlegern aus jedem EU Land bereitgestellt werden darf,
            interessieren, müssen Sie sich lediglich vorher registrieren und
            einen Code anfordern, um alle weiteren Produktinformationen dieser
            Anlage einsehen zu können. Besteht bereits eine Registrierung,
            reicht die einfache Anmeldung, um einen Code anzufordern, aus.
            Danach ist keine weitere Registrierung mehr notwendig. Sie können
            jederzeit per Anmeldung Ihren Account einsehen und dann das von
            Ihnen ausgewählte Produkt zeichnen.
          </div>
          <div className="flex items-center justify-center mt-4">
            <Button
              onClick={() => navigate("/")}
              text="Unser aktuelles Anlageangebot finden Sie hier"
              contain
            />
          </div>
        </div>
        <div>
          <Divider text="Schritt 2: Kapitalanlage zeichnen" />
          <div className="flex flex-col gap-4 px-6">
            <div>
              Wenn Sie sich für ein Investment entschieden haben, klicken Sie in
              der Projektprofilseite der gewünschten Anlage auf „Jetzt
              zeichnen“.
            </div>
            <div>
              Wir werden Sie im weiteren Registrierungsprozess bitten, Ihren
              Namen, Meldeadresse sowie Angaben zu Ihrem Geburtsdatum, Ihrer
              Steuernummer und Ihrer Kontodaten für die Zahlung von Dividenden
              anzugeben.
            </div>
            <div>
              Falls Sie ein Wallet zur Verwaltung von Tokens benötigen und noch
              keins erstellt haben, können Sie sich über die Web-Extension
              MetaMask ein eigenes Wallet erstellen. Eine genaue Anleitung dazu
              finden Sie hier als{" "}
              <a
                href="https://smartefunding.s3.eu-central-1.amazonaws.com/static/Metamask-Anleitung-ohne-Branding.pdf"
                target="_blank"
                className="underline"
              >
                PDF
              </a>{" "}
              oder hier als{" "}
              <a
                href="https://youtu.be/NXqC5agIwh8"
                target="_blank"
                className="underline"
              >
                Video
              </a>
              .
            </div>
            <div>
              Bitte überprüfen Sie dann noch einmal Ihre Daten auf Richtig- und
              Vollständigkeit und bestätigen Ihre Zeichnung nun verbindlich. Sie
              haben ein 14-tägiges Widerrufsrecht. Zusätzlich erhalten Sie alle
              Verträge und Formulare per E-Mail. In Ihrem Log-in Bereich können
              sie ebenfalls eingesehen werden.
            </div>
            <div>
              Um noch einmal einen genauen Überblick über den
              Online-Zeichnungsprozess der verschiedenen Finanzprodukte über die
              Software SMARTeFunding zu erhalten, schauen Sie sich hier den
              gesamten Zeichnungsprozess alls{"  "}
              <a
                href="https://youtu.be/LrzuboexlZA"
                className="underline"
                target="_blank"
              >
                Video
              </a>{" "}
              an.
            </div>
          </div>
        </div>
        <div>
          <Divider text="Schritt 3: Von attraktiven Renditen profitieren" />
          <div className="px-6">
            Den Überblick über Ihre Kapitalanlage behalten Sie in Ihrem
            persönlichen Online-Bereich unserer Plattform. Dort können Sie Daten
            ändern und alle wichtigen Dokumente und Verträge herunterladen.
            Profitieren Sie nun von Ihrer getätigten Kapitalanlage.
          </div>
          <div className="flex items-center justify-center mt-4">
            <Button
              onClick={() => navigate("/")}
              text="Unser aktuelles Anlageangebot finden Sie hier"
              contain
            />
          </div>
        </div>
      </div>
    </div>
  );
}
