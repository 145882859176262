import { renderHtmlFromRaw, isEmptyHTML } from "../utils/htmlUtils";
import Divider from "./Divider";

export default function Welcome({ pageData }) {
  return (
    <div className="flex justify-center">
      {pageData && (
        <div className="flex flex-col items-center justify-center gap-4 p-1 mx-16 max-w-full sm:max-w-[70rem]">
          <Divider text="Herzlich Willkommen" />
          <div className="flex flex-col items-start sm:flex-row gap-8">
            {pageData.welcomeImage && (
              <div className="flex justify-center items-center mt-1 flex-col gap-2">
                <div
                  className="rounded-full bg-cover bg-no-repeat bg-center w-40 h-40"
                  style={{ backgroundImage: `url(${pageData.welcomeImage})` }}
                ></div>
                <div>
                  {!isEmptyHTML(pageData.ceoTitle) && (
                    // ceo name and title
                    <div>{renderHtmlFromRaw(pageData.ceoTitle)}</div>
                  )}
                </div>
              </div>
            )}
            {pageData.welcomeImage2 && (
              <div className="flex justify-center items-center mt-1 flex-col gap-2">
                <div
                  className="rounded-full bg-cover bg-no-repeat bg-center w-40 h-40"
                  style={{ backgroundImage: `url(${pageData.welcomeImage2})` }}
                ></div>
                <div>
                  {!isEmptyHTML(pageData.ceoTitle2) && (
                    // ceo name and title
                    <div>{renderHtmlFromRaw(pageData.ceoTitle2)}</div>
                  )}
                </div>
              </div>
            )}
            {pageData.welcomeImage3 && (
              <div className="flex justify-center items-center mt-1 flex-col gap-2">
                <div
                  className="rounded-full bg-cover bg-no-repeat bg-center w-40 h-40"
                  style={{ backgroundImage: `url(${pageData.welcomeImage3})` }}
                ></div>
                <div>
                  {!isEmptyHTML(pageData.ceoTitle3) && (
                    // ceo name and title
                    <div>{renderHtmlFromRaw(pageData.ceoTitle3)}</div>
                  )}
                </div>
              </div>
            )}
            {pageData.welcomeImage4 && (
              <div className="flex justify-center items-center mt-1 flex-col gap-2">
                <div
                  className="rounded-full bg-cover bg-no-repeat bg-center w-40 h-40"
                  style={{ backgroundImage: `url(${pageData.welcomeImage4})` }}
                ></div>
                <div>
                  {!isEmptyHTML(pageData.ceoTitle4) && (
                    // ceo name and title
                    <div>{renderHtmlFromRaw(pageData.ceoTitle4)}</div>
                  )}
                </div>
              </div>
            )}
            {pageData.welcomeImage5 && (
              <div className="flex justify-center items-center mt-1 flex-col gap-2">
                <div
                  className="rounded-full bg-cover bg-no-repeat bg-center w-40 h-40"
                  style={{ backgroundImage: `url(${pageData.welcomeImage5})` }}
                ></div>
                <div>
                  {!isEmptyHTML(pageData.ceoTitle5) && (
                    // ceo name and title
                    <div>{renderHtmlFromRaw(pageData.ceoTitle5)}</div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="text-lg overflow-x-hidden	max-w-full">
            {!isEmptyHTML(pageData.welcomeDescription) && (
              <div>{renderHtmlFromRaw(pageData.welcomeDescription)}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
