export function productKindToName(kind) {
  switch (kind) {
    case "kind0":
      return "Wertpapier WIB - Eigenemission";
    case "kind1":
      return "Tokenisiertes Wertpapier";
    case "kind2":
      return "Wertpapier WIB - 32 KwG";
    case "kind3":
      return "Wertpapier WIB - 32 KwG - Tokenisiert";
    case "kind4":
      return "Wertpapier Prospektpflichtig - Eigenemission";
    case "kind5":
      return "Wertpapier Prospektpflichtig - Eigenemission - Tokenisiert";
    case "kind6":
      return "Wertpapier Prospektpflichtig - 32 KwG";
    case "kind7":
      return "Wertpapier Prospektpflichtig - 32 KwG - Tokenisiert";
    case "kind8":
      return "VIB - 34f III";
    case "kind9":
      return "VIB Prospektpflichtig - 34f III";
    case "kind10":
      return "AIF - Eigenemission";
    case "kind11":
      return "AIF - 34f II";
  }
}

export function securityTypeToName(type, isTokenized) {
  return (
    <div>
      {type === "0" && (
        <>
          <div>Inhaberschuld-</div>
          <div>verschreibung</div>
        </>
      )}
      {type === "1" && (
        <>
          <div>Orderschuld-</div>
          <div>verschreibung</div>
        </>
      )}
      {type === "2" && <>Genussschein</>}
      {isTokenized && (
        <div>
          <div>(Tokenisiert)</div>
        </div>
      )}
    </div>
  );
}

export function createImageArray(product) {
  const imageFields = ["img1", "img2", "img3", "img4", "img5", "img6"];

  let images = [];

  imageFields.forEach((imageField) => {
    if (product[imageField]) {
      images.push({
        original: product[imageField],
      });
    }
  });

  return images;
}
