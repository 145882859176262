import { Button, Form, Input } from "antd";
import "antd/dist/antd.css";
import { login, setPassword } from "../api/userApi";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import Header from "./common/Header";
import { loginResponseHandler } from "../utils/loginUtils";

export default function SetPassword({ user, pageData }) {
  let navigate = useNavigate();
  async function onActivate(values) {
    const res = await setPassword(
      user.email,
      values.newPassword,
      user.activationCode
    );

    if (res.status === 200) {
      const resLogin = await login(user.email, values.newPassword, false);

      if (resLogin.status === 200) {
        notification.success({
          message: "Registrierung ist abgeschlossen!",
          description: "Sie können nun einen Private Placement Code anfordern",
        });
        loginResponseHandler(resLogin, navigate);
      }
    } else {
      notification.error({
        message: "Fehler !",
        description: "Registrierung ist fehlgeschlagen",
      });
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="flex flex-col items-center mt-10">
      <div className="mb-16">
        <Header
          header="Vergeben Sie ein Passwort"
          subHeader="Geben Sie hier ihr Passwort an, welches Sie zum Anmelden benutzen möchten."
        />
        <div className="mt-2">
          <ul>
            <li>Muss 9 Zeichen oder länger sein</li>
            <li>Muss mindestens einen Kleinbuchstaben beinhalten</li>
            <li>Muss mindestens einen Großbuchstaben beinhalten</li>
            <li>Muss mindestens eine Zahl beinhalten</li>
            <li>
              Muss mindestens eines der folgender Sonderzeichen beinhalten: ?,
              !, @, #, &, %
            </li>
          </ul>
        </div>
      </div>
      <Form
        name="basic"
        labelCol={{
          span: 0,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onActivate}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Passwort"
          name="newPassword"
          rules={[
            {
              required: true,
              message: "Bitte geben Sie ihr Passwort an",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <div className="flex justify-end gap-4 items-center">
          <Button
            style={{
              border: "none",
              backgroundColor: "orange",
              color: "white",
            }}
            type="primary"
            htmlType="submit"
          >
            Bestätigen
          </Button>
        </div>
      </Form>
    </div>
  );
}
