import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";

export function fromRaw(raw) {
  if (raw) {
    try {
      let content = JSON.parse(raw);
      return EditorState.createWithContent(convertFromRaw(content));
    } catch {
      /**
       * this is needed for legacy reasons:
       * old text was saved as a text, not as a HTML,
       * this is why we need to convert it into an EditorState compatible
       * format.
       */
      let content = {
        blocks: [
          {
            key: "d4idd", // do not ask me what this is for
            text: raw,
            type: "unstyled",
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [],
            data: {},
          },
        ],
        entityMap: {},
      };
      return EditorState.createWithContent(convertFromRaw(content));
    }
  }
}

export function toHTML(editorState) {
  if (editorState) {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  }

  // simply return an empty div tag if no editorState is provided
  return "<div></div>";
}

export function htmlTag(html) {
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
}

// combine all things lol
// this is so bad
export function renderHtmlFromRaw(raw) {
  return htmlTag(toHTML(fromRaw(raw)));
}

export function isEmptyHTML(raw) {
  if (!raw) return true;

  try {
    let content = JSON.parse(raw);
    return content.blocks[0].text === "";
  } catch {
    if (raw && raw === "") return true;
    return false;
  }
}
