import { renderHtmlFromRaw } from "../utils/htmlUtils";

export default function LegalNotice({ product }) {
  return (
    <>
      {product.legalNotice && (
        <div className="border-solid border-2 border-gray-300 mt-2 p-4 w-full">
          <div className="font-bold">Risikohinweis</div>
          {renderHtmlFromRaw(product.legalNotice)}
        </div>
      )}
    </>
  );
}
