import { createChatBotMessage } from "react-chatbot-kit";
import HeaderChatbot from "./HeaderChatbot";
import MyAvatar from "./MyAvatar";
import { getCookie } from "../../utils/cookieUtils";
import { whiteOrBlack } from "../../utils/colorUtils";
import ChatbotMain  from "./ChatbotMain";
import ChatbotAnswers from "./ChatbotAnswers";


const config = {
  initialMessages: [
    createChatBotMessage(`Hallo und herzlich willkommen!`, {
      withAvatar: true,
      widget: "Chatbot",
      delay: 500,
    }),
  ],
  customComponents: {
    header: (props) => <HeaderChatbot {...props} />,
    botAvatar: (props) => <MyAvatar {...props} />,
  },
  customStyles: {
    botMessageBox: {
      backgroundColor: getCookie("secondaryColor"),
      color: whiteOrBlack(getCookie("secondaryColor")),
    },
    chatButton: {
      backgroundColor: getCookie("primaryColor"),
      color: whiteOrBlack(getCookie("primaryColor")),
    },
  },
  state: {
    chatbotItems: [],
  },
  widgets: [
    {
      widgetName: "Chatbot",
      widgetFunc: (props) => <ChatbotMain {...props} />,
      mapStateToProps: ["chatbotItems"],
    },
    {
      widgetName: "chatbotAnswers",
      widgetFunc: (props) => <ChatbotAnswers {...props} />,
      mapStateToProps: ["messages"],
    },
  ],
};

export default config;
