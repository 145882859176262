import ProgressBar from "../common/ProgressBar";
import { toEuro } from "../../utils/currencyUtils";

export default function ProductProgress({ product }) {
  return (
    <>
      {product && (
        <div className="text-sm flex flex-col gap-2">
          <div>bereits investiert: {toEuro(product.investedVolume)}</div>
          <ProgressBar
            progressInPercent={Math.round(
              (product.investedVolume / product.volume) * 100
            )}
          />
        </div>
      )}
    </>
  );
}
