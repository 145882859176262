// 123455.789 -> 123.456,79 € (string)
export function toEuro(number) {
  return new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  }).format(number);
}

// Round -> Float -> replace . with , -> add € -> return string
export function round(value) {
  return ((Math.round(value * 100) / 100).toFixed(2) + " €").replace(".", ",");
}
