import React, { useEffect, useState } from 'react'
import { getLandingPageData } from '../../api/superuserApi';

const MyAvatar = () => {
    const [pageData, setPageData] = useState();

    useEffect(() => {
      onInit();
    }, []);
    async function onInit() {
      const res = await getLandingPageData();

      setPageData(res);
    }
  return (
    <div><img src={pageData?.welcomeImage}  className="w-12 rounded-full mr-4"  alt='Chatbot'/></div>
  )
}

export default MyAvatar