export default function PageNotFound() {
  return (
    <div className="flex flex-col justify-center items-center gap-4 mt-40">
      <div className="font-bold text-3xl">
        Diese Seite konnte leider nicht gefunden werden.
      </div>
      <div>
        Die gesuchte Seite existiert nicht (mehr) oder wurde verschoben.
      </div>
    </div>
  );
}
