const EMOJI_PROPS = "text-4xl";

export default function CountryEmoji({ code }) {
  function show() {
    switch (code) {
      case "de":
        return (
          <div className="flex">
            <span role="img" aria-label="sheep" className={EMOJI_PROPS}>
              🇩🇪
            </span>
          </div>
        );
      // TODO: refactor this
      case "Deutschland": // Deutschland needed for legacy reasons
        return (
          <div className="flex">
            <span role="img" aria-label="sheep" className={EMOJI_PROPS}>
              🇩🇪
            </span>
          </div>
        );

      case "at":
        return (
          <div className="flex">
            <span role="img" aria-label="sheep" className={EMOJI_PROPS}>
              🇦🇹
            </span>{" "}
          </div>
        );

      case "ch":
        return (
          <div className="flex">
            <span role="img" aria-label="sheep" className={EMOJI_PROPS}>
              🇨🇭
            </span>{" "}
          </div>
        );
    }
  }

  return <div>{show()}</div>;
}
