import FileLink from "../common/FileLink";

export default function ProductFiles({ product }) {
  return (
    <div className="flex flex-col gap-2">
      {product.bondConditions && <FileLink url={product.bondConditions} />}
      {product.wib && <FileLink url={product.wib} />}
      {product.customerInformation && (
        <FileLink url={product.customerInformation} />
      )}
      {product.brochure && <FileLink url={product.brochure} />}
      {product.isTokenized && (
        <FileLink
          url={
            "https://smartefunding.s3.eu-central-1.amazonaws.com/static/Metamask-Anleitung-ohne-Branding.pdf"
          }
        />
      )}
    </div>
  );
}
