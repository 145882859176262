// "2021-11-23" -> date object
export function stringToDateObject(dateString) {
  if (typeof dateString === "string") {
    var parts = dateString.split("-");
    return new Date(parts[0], parts[1] - 1, parts[2]);
  }

  return dateString;
}

// number of days between `date1` and `date2`
export function getNumberOfDaysBetweenDates(date1, date2) {
  return Math.floor((Date.parse(date1) - Date.parse(date2)) / 86400000) + 1;
}

export function diffInYears(d2, d1) {
  var diff = (d2.getTime() - d1.getTime()) / 1000;
  diff /= 60 * 60 * 24;
  return Math.abs(Math.round(diff / 365.25));
}

export function diffInMonths(d2, d1) {
  let yearsDiff = d2.getFullYear() - d1.getFullYear();
  let monthsDiff = d2.getMonth() - d1.getMonth();
  let totalMonthsDiff = yearsDiff * 12 + monthsDiff;
  return Math.abs(totalMonthsDiff);
}