import { renderHtmlFromRaw, isEmptyHTML } from "../utils/htmlUtils";
import ProductCard from "./product/ProductCard";

export default function Hero({ product, pageData }) {
  return (
    <>
      {pageData && (
        <div
          className="pb-20 p-10 flex flex-col gap-2 bg-no-repeat bg-cover"
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(255, 255, 255, 1.0), rgba(0, 0, 0, 0.1)), url(${pageData.landingPageHeroImage})`,
          }}
        >
          <div className="md:ml-32 flex md:flex-row flex-col space-around md:gap-2 gap-8  md:items-start">
            <div
              className="flex flex-col gap-4 md:w-6/12 p-4 border-2 border-slate-200"
              style={{
                backgroundColor: "rgba(255, 255, 255, .7)",
                 overflow: "auto",           
                 wordBreak: "normal",
              }}
            >
              {!isEmptyHTML(pageData.header1) && (
                <div
                  className="primary-color text-4xl sm:text-5xl font-bold"
                  lang="de"
                >
                  {renderHtmlFromRaw(pageData.header1)}
                </div>
              )}
              {!isEmptyHTML(pageData.header2) && (
                <div className="text-3xl sm:text-4xl secondary-color">
                  {renderHtmlFromRaw(pageData.header2)}
                </div>
              )}
              {!isEmptyHTML(pageData.header3) && (
                <div className="text-2xl sm:text-3xl secondary-color">
                  {renderHtmlFromRaw(pageData.header3)}
                </div>
              )}
            </div>
            <div className="md:w-6/12 flex justify-center">
              <ProductCard product={product} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
