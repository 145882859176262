import Button from "./common/Button";
import ProductTable from "./product/ProductTable";
import LegalNotice from "./LegalNotice";
import ProductProgress from "./product/ProductProgress";
import { useSalesrepUuid } from "../utils/cookieUtils";

export default function ZeichnenCard({ product, pageData }) {
  const uuid = useSalesrepUuid();

  return (
    <>
      {product && pageData && (
        <div className="max-w-sm items-center flex flex-col w-screen">
          <div className="border-solid border-2 border-gray-300 h-fit w-full items-center flex flex-col">
            <div className="flex flex-col items-center">
              <div className="mt-4">
                <Button
                  text={
                    product.isZeichnable
                      ? "Jetzt investieren"
                      : "in Kürze verfügbar"
                  }
                  disabled={!product.isZeichnable}
                  onClick={() =>
                    window.open(
                      `https://${
                        pageData.investorSubdomain
                      }.investoraccount.eu/signup${
                        uuid && `?salesrep_uuid=${uuid}`
                      }`
                    )
                  }
                />
              </div>
              <ProductTable product={product} />
            </div>
            {pageData.phoneNumber && (
              <div className="flex gap-2">
                <div>Telefon-Hotline:</div>
                <div>
                  <b>{pageData.phoneNumber}</b>
                </div>
              </div>
            )}
            <div className="mt-4 mb-4">
              <ProductProgress product={product} />
            </div>
          </div>
          <LegalNotice product={product} />
        </div>
      )}
    </>
  );
}
