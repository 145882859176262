import { whiteOrBlack } from "../../utils/colorUtils";
import { getCookie } from "../../utils/cookieUtils";

export default function Button({
  text,
  onClick,
  contain = false,
  disabled = false,
}) {
  return (
    <button
      className={`text-lg font-bold rounded shadow-lg ${
        contain ? "w-contain px-2" : "w-[19rem]"
      } h-14 text-white bg-secondary-color`}
      type="button"
      style={{
        color: whiteOrBlack(getCookie("secondary-color")),
      }}
      onClick={() => onClick()}
      disabled={disabled}
    >
      {text}
    </button>
  );
}
