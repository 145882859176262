import Navbar from "./Navbar";
import { Routes, Route } from "react-router-dom";
import Footer from "./Footer";
import Home from "./Home";
import AboutUs from "./AboutUs";
import PageNotFound from "./PageNotFound";
import Login from "./Login";
import Header from "./Header";
import HowItWorks from "./HowItWorks";
import ProductOverview from "./product/ProductOverview";
import { getLandingPageData } from "../api/superuserApi";
import { useEffect, useState } from "react";
import { setFavicon } from "../utils/brandingUtils";
import SignupOverview from "./SignupOverview";
import RequestProductCode from "./RequestProductCode";
import { setCookie, useSalesrepUuid } from "../utils/cookieUtils";
import { setColorSheme } from "../utils/colorUtils";
import ResetPasswordRequest from "./ResetPasswordRequest";
import ResetPassword from "./ResetPassword";
import Loading from "./common/Loading";
import Salesrep from "./salesrep/Salesrep";
import Logout from "./Logout";
import { BackTop } from 'antd';


export default function Body() {
  const [isLoading, setIsLoading] = useState(false);
  const [pageData, setPageData] = useState();

  const uuid = useSalesrepUuid();

  useEffect(() => {
    onInit();
  }, []);
  async function onInit() {
    setIsLoading(true);
    const res = await getLandingPageData();

    setPageData(res);
    setCookie("investorSubdomain", res.investorSubdomain, 7);
    setColorSheme(res.landingPagePrimaryColor, res.landingPageSecondaryColor);
    setFavicon(res.landingPageFaviconFile);

    setIsLoading(false);
  }
  return (
    <>
      <Loading isLoading={isLoading} />
      <div className="h-screen flex flex-col justify-between space-between">
        <div>
          <Header pageData={pageData} />
          <Navbar pageData={pageData} />
          <Routes>
            <Route path="/" element={<Home pageData={pageData} />} />
            <Route
              path="/product/:productId"
              element={<ProductOverview pageData={pageData} />}
            />
            <Route
              path="/product/:productId/request-ppc"
              element={<RequestProductCode />}
            />
            <Route path="/about-us" element={<AboutUs pageData={pageData} />} />
            <Route path="/how-it-works" element={<HowItWorks />} />
            <Route
              path="/salesrep"
              element={<Salesrep pageData={pageData} />}
            />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route
              path="/signup"
              element={<SignupOverview pageData={pageData} />}
            />
            <Route
              path="/reset-password-request"
              element={<ResetPasswordRequest />}
            />
            <Route
              path="/reset-pass/:email/:resetCode"
              element={<ResetPassword />}
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </div>
        <Footer pageData={pageData} />
      </div>
      <BackTop />
    </>
  );
}
