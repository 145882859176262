import { PhoneFilled } from "@ant-design/icons";
import SocialMedia from "./SocialMedia";

export default function Header({ pageData }) {
  return (
    <>
      {pageData && (
        <div className="flex justify-between p-4">
          <SocialMedia pageData={pageData} />
          {pageData.phoneNumber && (
            <div className="flex items-center gap-2 text-xs">
              {pageData.phoneNumber}
              <PhoneFilled />
            </div>
          )}
        </div>
      )}
    </>
  );
}
