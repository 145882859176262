import SalesrepAbout from "./SalesrepAbout";
import SalesrepBrokeraccountButton from "./SalesrepBrokeraccountButton";
import SalesrepHero from "./SalesrepHero";
import SalesrepHowItWorks from "./SalesrepHowItWorks";

export default function Salesrep({ pageData }) {
  return (
    <>
      {pageData && (
        <div className="flex flex-col gap-8 mb-4 items-center">
          <div className="hidden md:block w-full">
            <SalesrepHero pageData={pageData} />
          </div>
          <SalesrepAbout pageData={pageData} />
          <SalesrepHowItWorks />
          <SalesrepBrokeraccountButton pageData={pageData} />
        </div>
      )}
    </>
  );
}
