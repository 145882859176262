import React, { useEffect, useState } from 'react'
import { getLandingPageData } from '../../api/superuserApi';
import { getCookie, setCookie } from '../../utils/cookieUtils';
import { setColorSheme, whiteOrBlack } from '../../utils/colorUtils';
import { setFavicon } from '../../utils/brandingUtils';

const HeaderChatbot = ( props ) => {
    const [pageData, setPageData] = useState();

    useEffect(() => {
      onInit();
    }, []);
    async function onInit() {
 
      const res = await getLandingPageData();

      setPageData(res);
      setCookie("investorSubdomain", res.investorSubdomain, 7);
      setColorSheme(res.landingPagePrimaryColor, res.landingPageSecondaryColor);
      setFavicon(res.landingPageFaviconFile);

   
    }
    console.log(pageData);
  return (
    <div
      style={{
        backgroundColor: pageData?.landingPagePrimaryColor,
        color: whiteOrBlack(getCookie("primary-color")),
      }}
      className="p-5 rounded-t-lg font-bold text-base"
    >
      {pageData?.investorSubdomain.toUpperCase()} Assistant
    </div>
  );
}

export default HeaderChatbot