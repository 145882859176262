export default function PrivatePlacementCard({ product }) {
  return (
    <div className="flex flex-col gap-4 mt-2 mb-7">
      <div className="flex flex-col items-center">
        <div className="text-blue-900 font-bold">max. Anlegerkapazität:</div>
        <div className="text-blue-900 font-bold">{product.numberOfCodes}</div>
      </div>
      <div className="flex flex-col items-center">
        <div className="font-light">interessierte Anleger:</div>
        <div className="font-bold">
          {product.numberOfCodes - product.numberOfAvailableCodes}
        </div>
      </div>
      <div className="flex flex-col items-center">
        <div className="font-light">Noch offene Plätze:</div>
        <div className="font-bold">{product.numberOfAvailableCodes}</div>
      </div>
    </div>
  );
}
