import Gallery from "../Gallery";
import ProductCard from "./ProductCard";

export default function MainProduct({ product, flip }) {
  return (
    <div className="flex justify-center items-center gap-8 p-4 mr-20 ml-20 ">
      {flip ? (
        <>
          <div className="hidden md:block w-[40rem]">
            <Gallery product={product} />
          </div>
          <ProductCard product={product} showLogo />
        </>
      ) : (
        <>
          <ProductCard product={product} showLogo />
          <div className="hidden md:block w-[40rem]">
            <Gallery product={product} />
          </div>
        </>
      )}
    </div>
  );
}
