import { useState } from "react";
import Button from "../common/Button";
import ProductTable from "./ProductTable";
import { Link, useNavigate } from "react-router-dom";
import PrivatePlacementCard from "../PrivatePlacementCard";
import { Modal } from "antd";
import { isLoggedIn } from "../../utils/userUtils";
import CountryEmoji from "../common/CountryFlag";
import { CaretRightOutlined } from "@ant-design/icons";

export default function ProductCard({ product, showLogo = false }) {
  const [isNotLoggedInModalVisible, setIsNotLoggedInModalVisible] = useState();
  let navigate = useNavigate();

  function onOk() {
    navigate("/login");
  }

  function onCancel() {
    setIsNotLoggedInModalVisible(false);
  }

  /**
   * we do not want to show the more info button in 2 cases:
   *
   * 1) product is not zeichnable.
   * 2) product is zeichnable, but is a private placement and all
   *    private placement codes are used up.
   */
  function showMoreInfoButton() {
    if (product.withPrivatePlacement) {
      // could only check for < but = is safer, lol
      if (product.numberOfAvailableCodes <= 0) return false;
    }

    return true;
  }

  function onMoreInfo() {
    // if it is a private placement but the investor is not logged in,
    // we show the please-log-in modal.
    if (product.withPrivatePlacement) {
      if (!isLoggedIn()) {
        setIsNotLoggedInModalVisible(true);
        return;
      }
    }
    navigate(`/product/${product.id}`);
  }

  return (
    <>
      {product && (
        <div>
          <div className="flex flex-col items-center justify-between w-80 min-h-full bg-white/70 border-gray-200 border-2 p-2">
            <img
              className="w-80 h-50 object-contain"
              src={showLogo ? product.logo : product.img1}
            />
            <div className="mt-3 text-xl mb-1">{product.name}</div>
            {product.withPrivatePlacement && (
              <div className="text-center mt-1">
                Private Placement Zeichnung nur für die ersten 149
                interessierten Anleger pro EU-Staat.
              </div>
            )}
            {product.country && product.withPrivatePlacement && (
              <div className="p-4 flex justify-center items-center gap-2">
                <CountryEmoji code={"de"} /> /
                <CountryEmoji code={"at"} />
              </div>
            )}
            {product.withPrivatePlacement ? (
              <PrivatePlacementCard product={product} />
            ) : (
              <ProductTable product={product} />
            )}
            <Modal
              title="Information"
              visible={isNotLoggedInModalVisible}
              onOk={onOk}
              onCancel={onCancel}
              okText="Anmelden/Registrieren"
              cancelText="Abbrechen"
            >
              <div>
                <div>
                  Dieses Finanzprodukt ist ein Private Placement. Sie müssen
                  sich anmelden/registrieren um weitere Produkt Informationen
                  sehen zu können.
                </div>
                <div className="mt-2">
                  Möchten Sie sich anmelden/registrieren?
                </div>
              </div>
            </Modal>
            {product.withPrivatePlacement && (
              <div className="text-center mt-1">
                Aus gesetzlichen Gründen können Sie alle Produktinformationen
                erst nachdem Sie sich <br />
                <br />
                <span className="flex items-center justify-center">
                  <CaretRightOutlined />
                  <button
                    className="underline text-blue-900 font-bold"
                    onClick={onMoreInfo}
                  >
                    Anmelden / Registrieren
                  </button>
                </span>
                <br />
                und dann einen personalisierten Code anfordern, erhalten.
              </div>
            )}
            {product.kind !== "kind12"
              ? showMoreInfoButton() && (
                  <div className="mt-1">
                    <Button text="weitere Informationen" onClick={onMoreInfo} />
                  </div>
                )
              : null}
          </div>
        </div>
      )}
    </>
  );
}
